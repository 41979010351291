@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: "transparent";
}

::-webkit-scrollbar-thumb {
  background: #1178f880;
}

::-webkit-scrollbar-thumb:hover {
  background: #1178f8;
}

.video-cover video {
  object-fit: cover;
}

.video-contain video {
  object-fit: contain;
}

.MuiSlider-valueLabel {
  color: transparent;
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-top: 14px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  filter: invert(97%) sepia(100%) saturate(0%) hue-rotate(129deg)
    brightness(110%) contrast(102%);
}

input[type="time"]:focus-visible {
  outline: none;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.loading-skeleton-anime-dark {
  --base-color: #1a1c22;
  --highlight-color: #232830;
}

.loading-skeleton-anime-light {
  --base-color: #eef0f2;
  --highlight-color: #d3d7da;
}

.loading-skeleton-anime-default {
  --base-color: #333244;
  --highlight-color: #414053;
}

.loading-skeleton-anime {
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.loading-skeleton-anime::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #9ea1a3 !important;
}
